<template>
    <div class="col-12 col-lg-6 g c">
        <div class="card card-body">
            <p>
                📱 اعدادات تطبيق ولي الأمر
            </p>
            <app-collapse>
                <app-collapse-item class="itemm" title="🚨 الاخبار والتنبيهات">
                    <button class="btn btn-sm btn-primary" @click="settings.ads.unshift({
                        title: '',
                        content: '',
                        date: date,
                        url: '',
                        id: Math.random().toString()
                    });">
                        <i class="fa fa-plus"></i> اضافة خبر/تنبيه
                    </button>
                    <div class="card" style="margin-top: 5px" v-for="(ad) in settings.ads" :key="ad.id">
                        <div class="card-header">
                            <input type="text" placeholder="العنوان..." v-model="ad.title" class="form-control">
                        </div>
                        <div class="card-body">
                            <input type="text" placeholder="التفاصيل..." v-model="ad.content" class="form-control">
                            <br>
                            <div class="form-group">
                              <label for="">عن الضغط عليه</label>
                              <select class="form-control" v-model="ad.whatdo">
                                <option :value="undefined">لا شيئ</option>
                                <option value="whatsapp">افتح محادثة واتس اب</option>
                                <option value="link">افتح رابط</option>
                              </select>
                            </div>
                            <div class="form-group" v-if="ad.whatdo == 'whatsapp'">
                              <label for="">رقم الواتس اب</label>
                              <input type="number"
                                class="form-control text-center" v-model="ad.phone" placeholder="اكتب رقم الواتس اب هنا...">
                              <small id="helpId" class="form-text text-muted">بالصيغة الدولية مثل 966503332...</small>
                            </div>
                            <div class="form-group" v-if="ad.whatdo == 'link'">
                              <label for="">الرابط</label>
                              <input type="url"
                                class="form-control text-center" v-model="ad.link" placeholder="اكتب الرابط هنا...">
                              <small id="helpId" class="form-text text-muted">مثل: https://google.com/example...</small>
                            </div>
                            <a href="javascript:;" class="text-danger" @click="settings.ads = settings.ads.filter(function(x){
                                if(x.id == ad.id){
                                    return false
                                }else{
                                    return x
                                }
                            })"><u>
                                <i class="fa fa-trash"></i> حذف</u></a>
                        </div>
                    </div>
                </app-collapse-item>
                <app-collapse-item class="itemm" title="🔐 الحماية والخصوصية">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" value="yes" v-model="settings.no_add_students">
                        عدم السماح باضافة الطلاب من حسابي
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" value="yes" v-model="settings.require_phone">
                        المطالبة برقم الجوال عند اضافة الطالب <br>(وسيتم اضافة الطالب اذا كان رقم الجوال المكتوب مطابق لما في حساب الطالب)
                      </label>
                    </div>
                </app-collapse-item>
                <app-collapse-item class="itemm" title="👤 الملف الشخصي للطالب">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" value="yes" v-model="settings.no_calls">
                        ايقاف نظام المناداة
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" value="yes" v-model="settings.no_full_report">
                        ايقاف التقرير الشامل
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" value="yes" v-model="settings.no_report">
                        ايقاف تقرير الحضور والغياب
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" value="yes" v-model="settings.no_points">
                        ايقاف نقاط التقييم
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" value="yes" v-model="settings.no_health">
                        ايقاف الملاحظات الصحية
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" value="yes" v-model="settings.no_bis">
                        ايقاف المخالفات السلوكية
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" value="yes" v-model="settings.no_breaks">
                        ايقاف المواقف الطارئة
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" value="yes" v-model="settings.no_visits">
                        ايقاف الزيارات
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" value="yes" v-model="settings.no_tasks">
                        ايقاف المهام
                      </label>
                    </div>
                </app-collapse-item>
                <app-collapse-item class="itemm" title="💬 المحادثات">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" value="yes" v-model="settings.no_chat_teachers">
                        ايقاف التواصل مع المعلمين
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" value="yes" v-model="settings.no_chat_admin">
                        ايقاف التواصل مع الادارة
                      </label>
                    </div>
                </app-collapse-item>
            </app-collapse>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="save()">
                    <i class="fa fa-save"></i>
                    حفظ الاعدادات
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
export default {
  directives: {
    Ripple,
  },
  components: { 
        AppCollapse,
        AppCollapseItem,
    },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            logo: require('@/assets/images/school.png'),
            settings: {},
            date: new Date().toISOString().split('T')[0]
        }
    },
    created(){
        if(!checkPer("app-settings")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + '/user/general/settings/app-settings', {
            jwt: this.user.jwt
        }).then(function(r){
            for (const [key, value] of Object.entries(r.response)) {
                if(key != "ads"){
                    r.response[key] = value == 'yes' ? 'yes' : false
                }
            }
            g.settings = r.response
        })
    },
    methods: {
        save(){
            var g = this;
             $.post(api + '/user/general/settings/app-settings/save', {
                jwt: this.user.jwt,
                object: JSON.stringify(g.settings)
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    alert("تم الحفظ", 100)
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال", 200)
            })
        },
    }
}
</script>

<style>
.itemm{
    background: #fafafa; border: solid 1px #eee;margin-top: 5px
}
.itemm:hover{
    background: #eee !important;
}
</style>